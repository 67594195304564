/* Buttons helper */

.btn {
    @include transition(background-color 250ms ease);
    @include border-radius(30px);
    @include Font1;
    @include font-size(17px);
    display: inline-block;
    margin: 0;
    padding: 14px 20px;
    border: 0;
    letter-spacing: 1.5px;
    background: $color-black-normal;
    font-family: $font-secondary-regular;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    font-weight: 400;
    -webkit-appearance: none;
    line-height: 1em;
    transition: box-shadow 250ms ease;
    outline: none!important;
    &, &:visited, &:active, &:focus {
        color: white;
    }
    
    &:hover {
        transition: box-shadow 250ms ease;
        @include bp(medium) {
            text-decoration: none;
            cursor: pointer;
            color: white;
        }
    }
}

.btn-responsive {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    text-align: center;
}

.btn-responsive-medium{
    @include bp(medium){
        min-width: auto!important;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        text-align: center;       
    }
}

.btn-responsive-large{
    @include bp(large){
        min-width: auto!important;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        text-align: center;       
    }
}

.btn-icon{

}

.btn-small{
    padding: 10px 20px;
}

.btn-normal{
    padding: 16px 20px;
}

.btn-big{
    padding: 20px 20px;
}

.btn-min-width-small{
    min-width: 220px;
}

.btn-min-width{
    min-width: 250px;
    @include bp(rev-xssmall){
        min-width: 220px;
    }
}

.btn-pink-fill{
    background-color: $color-pink-normal;
    color: #ffffff;
    &, &:visited, &:active, &:focus {
        color: #ffffff;
    }
    &:hover{
        background-color: darken($color-pink-normal, 5%);
        color: #ffffff;
    }
}

.btn-blue-fill{
    background-color: $color-blue-dark;
    color: #ffffff;
    &, &:visited, &:active, &:focus {
        color: #ffffff;
    }
    &:hover{
        background-color: darken($color-blue-dark, 5%);
        color: #ffffff;
    }
}

.btn-black-outline{
    border: 1px solid #000000;
    color: #000000;
    &, &:visited, &:active, &:focus {
        color: #000000;
    }
    &:hover{
        border: 1px solid #000000;
        background-color: #000000;
        color: #ffffff;
        .btn-icon{
            color: #ffffff;
        }
    }
    .btn-icon{
        color: #000000;
    }
}

