/* Footer */

.footer{
	position: relative;
	z-index: 2;
	// @include bp(small){
	// 	margin-top: -230px;
	// }
}

.footer-bg{
	padding: 50px 40px 10px;
	background-color: $color-blue-normal;
	@include bp(rev-small){
		margin: 0 -15px;
	}
	@include bp(rev-xsmall){
		padding: 50px 20px 10px;
	}
}

.footer-item{
	margin-bottom: 40px;
	// @include bp(rev-medium){

	// }
}

.footer-item-mobile{
	@include bp(rev-xssmall){
		width: 100%;
		max-width: 100%;
		flex: 0 0 100%;
	}
}

.footer-item-mobile-center{
	@include bp(rev-xssmall){
		text-align: center;
		img{
			margin: 0 auto;
		}
	}
}


.footer-item-border{
	@include bp(medium){
		&:after{
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			display: block;
			width: 2px;
			height: 170px;
			transform: translateY(-50%);
			background-color: #4e556e;
		}
	}
}

.footer-text{
	color: #ffffff;
	font-family: $font-primary;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 5.13px;
	@include font-size(13px);
}

.footer-list{

}

.footer-list-item{
	display: block;
	margin-bottom: 5px;
	&:last-child{
		margin-bottom: 0;
	}
}

.footer-list-item-big{
	margin-bottom: 25px;
}

.footer-list-link{
	display: flex;
}

.footer-list-icon{
	width: 20px;
	max-width: 20px;
	flex: 0 0 20px;
	color: #ffffff;
}

.footer-list-text{
	color: #ffffff;
	line-height: 1.4;
	padding-left: 15px;
	@include font-size(13px);
	width: calc(100% - 20px);
	max-width: calc(100% - 20px);
	flex: 0 0 calc(100% - 20px);
}

.footer-list-text-upper{
	display: block;
	margin-bottom: 5px;
	font-weight: 600;
	letter-spacing: 0.2px;
	text-transform: uppercase;
}
