/* Header */

$main-bg:        #1BBC9B;
$nav-color:      $color-pink-normal;
$nav-bg-active:  $color-blue-normal;

.navigation{
    @include bp(rev-medium){
        .nav-collapse{
            position: fixed;
            left: 0;
            top: 60px;
            z-index: 98;
            padding: 0 25px 70px;
            overflow: auto;
            transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            -moz-transform: translateX(-100%);
            -o-transform: translateX(-100%);
            width: 100%;
            height: 100%;
            background-color: $nav-bg-active;
            @include transition(none 250ms ease);
        }

        .navigation-trigger {
            display: block;
            position: fixed;
            width: 70px;
            height: 60px;
            right: 0;
            top: 0;
            z-index: 200;
        }

        .navigation-trigger-icon {
            display: inline-block;
            position:  absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            width: 30px;
            height: 2px;
            font-weight: 300;
            background-color:  $nav-color;
            transition-property:  background-color, transform;
            transition-duration:  250ms;
            -webkit-transition-property:  background-color, transform;
            -webkit-transition-duration:  250ms;
            &:before,
            &:after {
                content: '';
                display: block;
                width: 30px;
                height: 2px;
                position:  absolute;
                background:  $nav-color;
                transition-property:  margin, transform;
                transition-duration:  250ms;
                -webkit-transition-property:  margin, transform;
                -webkit-transition-duration:  250ms;
            }
            &:before {
                margin-top: -10px;
            }
            &:after {
                margin-top: 10px;
            }
        }
        &.is-collapse .nav-collapse{
            transform: translateX(0);
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -o-transform: translateX(0);
            @include transition(transform 250ms ease);
        }
        &.is-collapse .navigation-trigger-icon {
            background:  rgba(0,0,0,0.0);
            &:before {
                margin-top: 0;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
            }
            &:after {
                margin-top: 0;
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
            }
        }
        .container-normal{
            padding: 0;
            width: 100%;
        }
    }
}


.header{

}

.navigation{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 0;
    padding: 0 20px;
    background-color: $color-blue-normal;
}


.header-logo{
    &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 230px;
        height: 100px;
        z-index: 0;
        background-color: $color-pink-normal;
        @include bp(excl-large){
            width: 170px;
        }
        @include bp(rev-medium){
            width: 160px;
            height: 64px;
        }
    }
    &:before{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 30px 115px 0 115px;
        border-color: $color-pink-normal transparent transparent transparent;
        pointer-events: none;
        @include bp(excl-large){
            border-width: 30px 85px 0 85px;
        }
        @include bp(rev-medium){
            bottom: -18px;
            border-width: 15px 80px 0 80px;
        }
    }
    @include bp(medium){
        position: absolute;
        top: 0;
        left: 15px;
        text-align: center;
        width: 230px;
        height: 130px;
        padding-top: 25px;
        @include bp(excl-large){
            width: 170px;
        }
    }
    @include bp(rev-medium){
        display: inline-block;
        z-index: 99;
        position: relative;
        padding: 5px 0;
    }
}


.header-image{
    position: relative;
    z-index: 1;
    margin: 0 auto;
    @include bp(rev-medium){
        top: 5px;
        left: 30px;
        width: 100px;
        height: auto;
    }
}

.nav-collapse{
    @include bp(medium){
        text-align: right;
    }
}

.menu-group{
    @include bp(medium){
        display: inline-block;
        vertical-align: middle;
    }
    @include bp(rev-medium){
        margin-top: 35px;
    }
}

.header-btn{
    @include bp(medium){
        display: inline-block;
        vertical-align: middle;
    }
    @include bp(excl-large){
        min-width: 200px;
    }
    @include bp(rev-medium){
        margin-top: 1em;
    }
}

.menu-list-list{
}

.menu-list-item{
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 15px;
    padding: 12px 0;
    @include bp(rev-medium){
        display: block;
        padding: 8px 0;
    }
}

.menu-list-link{
    display: block;
    padding: 15px 0;
    color: #edeff5;
    font-family: $font-secondary-regular;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2.77px;
    @include font-size(17px);
    @include bp(excl-large){
        padding: 16px 0;
        letter-spacing: 2px;
        @include font-size(14px);
    }
    &:hover,
    &.is-active{
        @include bp(medium){
            &:before{
                width: 100%;
            }
        }
        @include bp(rev-medium){
            color: $color-pink-normal;
        }
    }
    &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: $color-pink-normal;
        transition: width 400ms ease-out;
    }
    @include bp(rev-medium){
        padding: 15px 0;
    }
}

.contact-list-item{
    display: inline-block;
    margin: 0 15px 15px;
    &:last-child{
        margin-bottom: 0;
    }
}

.contact-list-link{
    display: flex;
}

.contact-list-icon{
    width: 20px;
    max-width: 20px;
    flex: 0 0 20px;
    color: #ffffff;
}

.contact-list-text{
    color: #ffffff;
    line-height: 1.4;
    padding-left: 10px;
    @include font-size(13px);
    width: calc(100% - 20px);
    max-width: calc(100% - 20px);
    flex: 0 0 calc(100% - 20px);
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    letter-spacing: 0.2px;
    text-transform: uppercase;
}