/* HR helper */

.hr{
	border: 0;
	margin-bottom: 15px;
	margin-top: 15px;
}

.hr-line {
    margin: 25px 0;
    height: 2px;
    background: #e1e5f6;
}

.hr-line-small {
    margin: 15px 0;
    height: 1px;
    background: #e1e5f6;
}