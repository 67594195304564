/* Typography */

.typography{
	p, 
    li, 
    td, 
    th {
        line-height: 1.5em;
    }

    p{
        &:last-child{
            margin-bottom: 0;
        }
    }

    p,
    ul,ol,
    h1, h2, h3, h4, h5, h6,
    table,
    fieldset,
    blockquote,
    iframe {
        margin-bottom: 1em;
    }

	h1{
		display: block;
		margin-top: 0;
		margin-bottom: 80px;
		line-height: 1;
		color: #000000;
		font-family: $font-primary;
		font-size: 70px;
		text-align: center;
		font-weight: 100;
		text-transform: uppercase;
		@include bp(rev-small){
			margin-bottom: 60px;
			font-size: 50px;
		}
		@include bp(rev-xsmall){
			font-size: 40px;
		}
		@include bp(rev-xssmall){
			font-size: 36px;
		}
	}

	h2{
		display: block;
		margin-top: 0;
		margin-bottom: 20px;
		line-height: 1;
		color: #000000;
		font-size: 30px;
		text-transform: uppercase;
		@include bp(rev-small){
			font-size: 26px;
		}
		@include bp(rev-xsmall){
			font-size: 24px;
		}
		@include bp(rev-xssmall){
			font-size: 22px;
		}
	}

	h3{
		display: block;
		margin-top: 0;
		margin-bottom: 20px;
		line-height: 1;
		color: #000000;
		font-size: 16px;
		text-transform: uppercase;
	}

	h4{
		display: block;
		margin-top: 0;
		margin-bottom: 20px;
		line-height: 1;
		color: #000000;
		font-size: 14px;
		text-transform: uppercase;
	}

	.lead {
        line-height: 1.6em;
        color: #333333;
        @include font-size(24px);
        @include bp(excl-medium){
            @include font-size(22px);
        }
        @include bp(excl-small){
            @include font-size(20px);
        }
    }

	a{
		color: rgba(0,0,0,0.8);
		text-decoration: underline;
		&:hover{
			text-decoration: underline;
		}
	}

	p{
		color: rgba(0,0,0,0.4);
		font-size: 14px;
		line-height: 24px;
		margin-bottom: 30px;
	}

	strong{
		color: rgba(0,0,0,0.8);
		font-weight: 700;
	}
	
	img{
		margin-bottom: 30px;
	}

	ul,
	ol{
		padding-left: 0;
		margin-bottom: 40px;
		list-style: none;
		li{
			position: relative;
			padding-left: 25px;
			color: rgba(0,0,0,0.4);
			font-size: 14px;
			line-height: 1.4;
			margin-bottom: 10px;
			@include bp(rev-small){
				margin-bottom: 15px;
				font-size: 14px;
			}
		}
	}

	ul{
		li{
			&:before{
				content: '\e91e';
				font-family: $project-name;
				color: rgba(0,0,0,0.6);
				font-size: 14px;
				line-height: 1.4;
				position: absolute;
				left: 0;
				top: 1px;
				text-align: center;
			}
		}
	}

	ol{
		counter-reset: item;
		list-style-position:inside;
		li{
			&:before{
				display: inline-block;
				position: absolute;
				top: 0;
				left: 0;
				content: counter(item);
				counter-increment: item;
				color: rgba(0,0,0,0.6);
				font-size: 14px;
				line-height: 1.4;
			}
		}
	}
}
