/* Placeholder helpers */

input::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #9ba3bf;
}

input::-moz-input-placeholder,
select::-moz-input-placeholder,
textarea::-moz-input-placeholder {
    color: #9ba3bf;
}

input:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #9ba3bf;
}


input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder{
    color: transparent;
    opacity: 1;
}

input:focus::-moz-input-placeholder,
textarea:focus::-moz-input-placeholder{
    color: transparent;
    opacity: 1;
}

input:focus:-moz-input-placeholder,
textarea:focus:-moz-input-placeholder{
    color: transparent;
    opacity: 1;
}