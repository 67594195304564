/* Margin helpers */

.margin-0 {
    margin: 0 !important;    
}

.margin-xs-0 {
    margin: 0 !important;
}

.margin-sm-0 {
    @include bp(small) {
        margin: 0 !important;
    }
}

.margin-md-0 {
    @include bp(medium) {
        margin: 0 !important;
    }
}

.margin-lg-0 {
    @include bp(large) {
        margin: 0 !important;
    }
}

.margin-top-10{
    margin-top: 10px;
}

.margin-top-20{
    margin-top: 20px;
}

.margin-top-20-medium{
    margin-top: 0;
    @include bp(medium){
        margin-top: 20px;
    }
}

.margin-top-30{
    margin-top: 30px;
}

.margin-top-30-medium{
    margin-top: 10px;
    @include bp(medium){
        margin-top: 30px;
    }
}

.margin-top-40{
    margin-top: 40px;
}

.margin-top-50{
    margin-top: 50px;
}

.margin-top-60{
    margin-top: 60px;
}

.margin-top-100{
    margin-top: 100px;
}

.margin-top-50-large{
    margin-top: 30px;
    @include bp(medium){
        margin-top: 50px;
    }
}

.margin-top-60-medium{
    margin-top: 30px;
    @include bp(small){
        margin-top: 60px;
    }
}

.margin-bottom-10{
    margin-bottom: 10px;
}

.margin-bottom-20{
    margin-bottom: 20px;
}

.margin-bottom-30{
    margin-bottom: 30px;
}

.margin-bottom-30-small{
    @include bp(rev-xsmall){
        margin-bottom: 30px;
    }
}

.margin-bottom-40{
    margin-bottom: 40px;
}

.margin-bottom-50{
    margin-bottom: 50px;
}

.margin-bottom-60{
    margin-bottom: 60px;
}

.margin-bottom-90{
    margin-bottom: 90px;
}

.margin-bottom-60-medium{
    margin-bottom: 40px;
    @include bp(medium){
        margin-bottom: 60px;
    }
}

.margin-top-05{
    margin-top: 0.5em;
}

.margin-top-1{
    margin-top: 1em;
}

.margin-top-2{
    margin-top: 2em;
}

.margin-top-3{
    margin-top: 3em;
}

.margin-top-4{
    margin-top: 4em;
}

.margin-top-6{
    margin-top: 6em;
}


.margin-bottom-1{
    margin-bottom: 1em;
}

.margin-bottom-2{
    margin-bottom: 2em;
}

.margin-bottom-3{
    margin-bottom: 3em;
}

.margin-bottom-4{
    margin-bottom: 4em;
}


.margin-bottom-70{
    margin-bottom: 70px;
}

.margin-right-1{
    margin-right: 1em;
}

.margin-right-2{
    margin-right: 2em;
}

.margin-top-lg-1{
    @include bp(rev-large){
        margin-top: 1em;
    }
}

.margin-top-lg-2{
    @include bp(rev-large){
        margin-top: 2em;
    }
}

.margin-top-lg-3{
    @include bp(rev-large){
        margin-top: 3em;
    }
}

.margin-top-lg-1{
    @include bp(rev-large){
        margin-top: 1em;
    }
}

.margin-top-md-4{
    @include bp(rev-medium){
        margin-top: 4em;
    }
}

.margin-top-md-3{
    @include bp(rev-medium){
        margin-top: 3em;
    }
}

.margin-top-md-2{
    @include bp(rev-medium){
        margin-top: 2em;
    }
}

.margin-top-md-1{
    @include bp(rev-medium){
        margin-top: 1em;
    }
}

.margin-top-sm-1{
    @include bp(rev-small){
        margin-top: 1em;
    }
}

.margin-top-sm-2{
    @include bp(rev-small){
        margin-top: 2em;
    }
}

.margin-top-sm-3{
    @include bp(rev-small){
        margin-top: 3em;
    }
}


.margin-top-sm-1{
    @include bp(rev-small){
        margin-top: 1em;
    }
}

.margin-top-xs-1{
    @include bp(rev-xsmall){
        margin-top: 1em;
    }
}

.margin-top-xs-2{
    @include bp(rev-xsmall){
        margin-top: 2em;
    }
}

.margin-top-xs-3{
    @include bp(rev-xsmall){
        margin-top: 3em;
    }
}

.margin-bottom-xs-1{
    @include bp(rev-xsmall){
        margin-bottom: 1em;
    }
}

.margin-bottom-sm-2{
    @include bp(rev-small){
        margin-bottom: 2em;
    }
}

.margin-bottom-sm-1{
    @include bp(rev-small){
        margin-bottom: 1em;
    }
}

.margin-bottom-md-3{
    @include bp(rev-medium){
        margin-bottom: 3em;
    }
}

.margin-bottom-md-2{
    @include bp(rev-medium){
        margin-bottom: 2em;
    }
}

.margin-bottom-md-1{
    @include bp(rev-medium){
        margin-bottom: 1em;
    }
}


.h-margin-top-0{
    h1, h2, h3, h4, h5{
        margin-top: 0;
    }
}

.h-margin-top-10{
    h1, h2, h3, h4, h5{
        margin-top: 10px;
    }
}

.h-margin-0{
    h1,h2,h3,h4,h5{
        margin: 0;
    }
}

.h-margin-02{
    .alfa, .beta, .gamma, .delta, .epsilon,
    h1,h2,h3,h4,h5{
        margin-bottom: 0.2em;
    }
}

.h-margin-05{
    .alfa, .beta, .gamma, .delta, .epsilon,
    h1,h2,h3,h4,h5{
        margin-bottom: 0.5em;
    }
}

.h-margin-1{
    .alfa, .beta, .gamma, .delta, .epsilon,
    h1,h2,h3,h4,h5{
        margin-bottom: 1em;
    }
}

.h-margin-2{
    .alfa, .beta, .gamma, .delta, .epsilon,
    h1,h2,h3,h4,h5{
        margin-bottom: 2em;
    }
}



.p-margin-0{
    p{
        margin-bottom: 0em;
    }
}


.p-margin-05{
    p{
        margin-bottom: 0.5em;
    }
}

.p-margin-1{
    p{
        margin-bottom: 1em;
    }
}

.p-margin-2{
    p{
        margin-bottom: 2em;
    }
}

$ways: "top" "right" "bottom" "left";

@each $item in $ways {
    .margin-#{$item}-0 {
        margin-#{$item}: 0 !important;
    }
    
    .margin-#{$item}-xs-0 {
        @include bp(xsmall) {
            margin-#{$item}: 0 !important;
        }
    }
    
    .margin-#{$item}-sm-0 {
        @include bp(small) {
            margin-#{$item}: 0 !important;
        }
    }
    
    .margin-#{$item}-md-0 {
        @include bp(medium) {
            margin-#{$item}: 0 !important;
        }
    }
    
    .margin-#{$item}-lg-0 {
        @include bp(large) {
            margin-#{$item}: 0 !important;
        }
    }

    .margin-bp-#{$item}-xs-0 {
        @include bp(rev-small) {
            margin-#{$item}: 0 !important;
        }
    }
    
    .margin-bp-#{$item}-sm-0 {
        @include bp(rev-small) {
            margin-#{$item}: 0 !important;
        }
    }
    
    .margin-bp-#{$item}-md-0 {
        @include bp(rev-medium) {
            margin-#{$item}: 0 !important;
        }
    }
    
    .margin-bp-#{$item}-lg-0 {
        @include bp(rev-large) {
            margin-#{$item}: 0 !important;
        }
    }

}