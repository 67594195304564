.js-slider{
    display: none;
    &.slick-initialized{
        display: block;
    }
}

.js-slider{
    .js-slide-item{
        outline: none;
    }
}



.slick-dots {
    // text-align: center;
    position: absolute;
    left: 0;
    width: 100%;
    margin: 0!important;
    bottom: -60px;
    @include bp(rev-xsmall){
        bottom: -30px;
    }
    li {
        display: inline-block;
        width: 30px;
        height: 30px;
        cursor: pointer;
        padding: 0!important;
        vertical-align: middle;
        position: relative;
        @include transition(all 250ms ease);
        &:after{
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 18px;
            height: 18px;
            border: 2px solid #499fa7;
            background-color: #ffffff;
            @include border-radius(100%);
        }
        &.slick-active,
        &:hover{
            &:after{
                border-color: $color-black-normal;
                background-color: $color-black-normal;
            }
        }
        button {
            &:before {
                content: none;
            }
        }
        button {
            display: none;
        }
    }
}

.slick-arrows {
    display: block;
    cursor: pointer;
    position: absolute;
    top: calc(50% - 60px);
    margin-top: -45px;
    z-index: 6;
    @include bp(rev-small){
        margin-top: -30px;
        top: calc(50% - 60px);
    }
    .icon{
        display: block;
        width: 40px;
        height: 90px;
        background-color: rgba(69, 75, 97, 0.7);
        text-align: center;
        color: #ffffff;
        line-height: 90px;
        @include font-size(22px);
        @include bp(rev-small){
            line-height: 60px;
            height: 60px;
        }
        &:hover{
            background-color: rgba(69, 75, 97, 1);
        }
    }
    &.slick-disabled{
        opacity: 0.4;
    }
    &.slick-prev {
        left: 0;
    }
    &.slick-next {
        right: 0;
    }
}