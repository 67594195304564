.opacity-0{
	opacity: 0;
}

.opacity-01{
	opacity: 0.1;
}

.opacity-025{
	opacity: 0.25;
}

.opacity-03{
	opacity: 0.3;
}

.opacity-05{
	opacity: 0.5;
}

.opacity-06{
	opacity: 0.6;
}

.opacity-07{
	opacity: 0.7;
}

.opacity-1{
	opacity: 1;
}