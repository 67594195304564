.map{
	width: 100%;
	height: 700px;
	position: relative;
	background-color: $color-blue-normal;
	@include bp(rev-small){
		height: 500px;
	}
	@include bp(rev-xsmall){
		height: 400px;
		// height: 200px;
	}
	.map-block{
		width: 100%;
		height: 100%;
	}
}

.section-map{
	padding: 0 40px 40px;
	margin-top: -60px;
    @include bp(rev-medium){
		margin-top: 0;
		padding: 40px 20px 0;
    }
}


.map-object{
	border: 0;
	position: relative;
	.window-block{
		position: relative;
		pointer-events: none;
		.gm-style-iw{
			&:after{
				opacity: 1;
				transition: opacity 100ms 100ms ease-out;
			}
		}
		> div{
			&:first-child{
				display: none;
			}
		}
	}
	.gm-style-iw {
		width: 200px!important;
		top: -10px!important;
		left: 0!important;
		position: relative!important;
		margin: 0 auto;
		overflow: visible;
		background-color: white;
		pointer-events: all;
		@include bp(small){
			top: 0!important;
			height: 200px;
			width: 480px!important;
		}
		@include bp(small){
			&:after{
				content: "";
				position: absolute;
				width: 0;
				height: 0;
				left: -9px;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				border: 13px solid black;
				border-color: transparent transparent #ffffff #ffffff;
				-webkit-transform-origin: 0 0;
				-ms-transform-origin: 0 0;
				transform-origin: 0 0;
				-webkit-transform: rotate(45deg) translateY(-50%);
				-ms-transform: rotate(45deg) translateY(-50%);
				transform: rotate(45deg) translateY(-50%);
				-webkit-box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.25);
				box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.25);
				top: 50%;
				margin-top: -10px;
				opacity: 0;
			}
		}
		@include bp(rev-small){
			&:after{
				content: "";
				position: absolute;
				width: 0;
				height: 0;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				border: 13px solid black;
				border-color: transparent transparent #ffffff #ffffff;
				-webkit-transform-origin: 0 0;
				-ms-transform-origin: 0 0;
				transform-origin: 0 0;
				-webkit-transform: rotate(-45deg);
				-ms-transform: rotate(-45deg);
				transform: rotate(-45deg);
				-webkit-box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.25);
				box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.25);
				margin-left: -18px;
				opacity: 0;
				left: 50%;
				bottom: -26px;
			}
		}
		div{
			overflow: visible!important;
			// display: table-cell!important;
			// vertical-align: middle;
			// &:last-child{
			// 	display: none!important;
			// }
		}
		p{
			margin: 0;
		}
	}
	.gm-ui-hover-effect{
		pointer-events: all;
		background: $color-pink-normal!important;
		cursor: pointer;
	    top: -30px!important;
	    pointer-events: auto;
	    @include bp(small){
	    	top: -10px!important;
	    	right: 10px!important;
	    }
	    @include bp(rev-small){
		    left: 50%!important;
		    margin-left: -20px!important;
		    right: auto!important;
	    }
	    &:hover{
			background: #f62653!important;
	    }
		&:after{
			content: "\e909";
			font-family: $project-name;
			font-size: 16px;
			display: block;
			text-align: center;
			line-height: 40px;
			color: white;
		}
		img{
			display: none!important;
		}
	}
	.gm-style-iw{
		> div{
			max-height: 100%!important;
			max-width: 100%!important;
		}
	}
}

