/* Image helpers */

.img {
    display: block;   
}

.img-center{
    margin: 0 auto;
}

.img-full{
    width: 100%;
}

.img-cover{
	height: 100%;
	object-fit: cover;
}

.img-contain{
	height: 100%;
    object-fit: contain;
    width: 100%;
}

.img-responsive{
	display: block;
	max-width: 100%;
	height: auto;
}

.img-hover{
	display: block;
	overflow: hidden;
	&:hover{
		> img{
			transform: scale(1.05, 1.05);
		}
	}
	img{
		transition: transform 400ms ease-out;
	}
}

.bg-image{
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.img-flex{
	display: flex;
	align-items: center;
	img{
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.img-flex-thumbnail{
	height: 120px;
}