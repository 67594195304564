/* Content */

section {
    position: relative;
    // z-index: 2;
    padding: 60px 10px 60px;
    @include bp(rev-small){
        padding: 60px 10px 60px;
    }
}

.no-padding{
    padding: 0;
}

.padding-style-small{
    padding: 40px 10px 40px;
    @include bp(rev-small){
        padding: 40px 10px 40px;
    }
}

.padding-style-big{
    padding: 100px 10px 200px;
    @include bp(rev-small){
        padding: 60px 10px 40px;
    }
}


.page-index{
    main{
        padding-top: 70px;
        @include bp(rev-medium){
            padding-top: 60px;
        }
    }
}

main{
    display: block;
    position: relative;
    padding-top: 70px;
    @include bp(rev-medium){
        padding-top: 60px;
    }
}


.container-full {
    padding: 0 15px;
}

.container-small,
.container-normal,
.container-large{
    margin: 0 auto;
    padding: 0 15px;
}

.container-small{
    @include bp(small){
        width: 680px;
    }
    @include bp(medium){
        width: 800px;
    }
    @include bp(large){
        width: 800px;
    }
    @include bp(xlarge){
        width: 800px;
    }
    @include bp(rev-small){
        width: 100%;
    }
}

.container-normal{
    @include bp(small){
        width: 680px;
    }
    @include bp(medium){
        width: 900px;
    }
    @include bp(large){
        width: 1120px;
    }
    @include bp(xlarge){
        width: 1120px;
    }
    @include bp(rev-small){
        width: 100%;
    }
}

.container-large{
    @include bp(small){
        width: 680px;
    }
    @include bp(medium){
        width: 900px;
    }
    @include bp(large){
        width: 1120px;
    }
    @include bp(xlarge){
        width: 1360px;
    }
    @include bp(rev-small){
        width: 100%;
    }
}