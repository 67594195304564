.section-hero{
	padding: 100px 10px 120px;
    @include bp(rev-medium){
        padding: 60px 10px 40px;
    }
}

.legend-group{

}

.legend-group-item{
	display: flex;
	align-items: center;
	float: left;
	border-radius: 20px;
	justify-content: space-between;
	background-color: $color-blue-normal;
	padding: 12px 20px;
	margin-right: 30px;
	margin-bottom: 15px;
	min-width: 200px;
	box-shadow: none;
	border: 0;
	outline: none;
	cursor: pointer;
	&:hover{
		background-color: $color-pink-normal;
		.legend-group-mark{
			color: #ffffff;
		}
	}
	@include bp(rev-medium){
		margin-right: 15px;
		min-width: 160px;
		padding: 12px 20px;
	}
	&:last-child{
		margin-right: 0;
	}
}

.legend-group-mark{
	color: $color-pink-normal;
	font-family: $font-primary;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 5.18px;
	margin-right: 10px;
	@include font-size(18px);
	@include bp(rev-medium){
		@include font-size(16px);
		margin-right: 5px;
	}
}

.legend-group-text{
	color: #edeff5;
	font-family: $font-secondary-regular;
	@include font-size(17px);
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 2.77px;
	@include bp(rev-medium){
		@include font-size(15px);
		letter-spacing: 2px;
	}
}

.quote{
	position: relative;
	padding-left: 20px;
	@include bp(rev-medium){
		padding-left: 60px;
	}
	&:before{
		content: '\e901';
		font-family: $project-name;
		position: absolute;
		top: -20px;
		left: -60px;
		display: block;
		color: #454b61;
		@include font-size(60px);
		@include bp(rev-medium){
			left: 0;
			@include font-size(40px);
		}
	}
}

.quote-author{
	padding-left: 20px;
	@include bp(rev-medium){
		padding-left: 60px;
	}
}