/* Position helpers */

.pos-r {
    position: relative;
}

.pos-f{
	position: fixed;
}

.pos-s{
	position: static;
}

.pos-a{
	position: absolute;
}

.pos-auto{
	position: inherit;
}