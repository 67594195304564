.dots{
	overflow: hidden;
}

.dots-25{
	max-height: 25px;
}

.dots-35{
	max-height: 35px;
}

.dots-55{
	max-height: 55px;
}

.dots-65{
	max-height: 65px;
}

.dots-75{
	max-height: 75px;
}

.dots-80{
	max-height: 80px;
}

.dots-90{
	max-height: 90px;
}

.dots-105{
	height: 105px;
}

.dots-160{
	height: 160px;
}
