/* Padding block */

.padding-0 {
    padding: 0 !important;    
}

.padding-xs-0 {
    padding: 0 !important;
}

.padding-sm-0 {
    @include bp(small) {
        padding: 0 !important;
    }
}

.padding-md-0 {
    @include bp(medium) {
        padding: 0 !important;
    }
}

.padding-lg-0 {
    @include bp(large) {
        padding: 0 !important;
    }
}


$ways: "top" "right" "bottom" "left";

@each $item in $ways {
    .padding-#{$item}-0 {
        padding-#{$item}: 0 !important;
    }
    
    .padding-#{$item}-xs-0 {
        padding-#{$item}: 0 !important;
    }
    
    .padding-#{$item}-sm-0 {
        @include bp(small) {
            padding-#{$item}: 0 !important;
        }
    }
    
    .padding-#{$item}-md-0 {
        @include bp(medium) {
            padding-#{$item}: 0 !important;
        }
    }
    
    .padding-#{$item}-lg-0 {
        @include bp(large) {
            padding-#{$item}: 0 !important;
        }
    }

    .padding-bp-#{$item}-0 {
        padding-#{$item}: 0 !important;
    }
    
    .padding-bp-#{$item}-xs-0 {
        @include bp(rev-xsmall) {
            padding-#{$item}: 0 !important;
        }
    }
    
    .padding-bp-#{$item}-sm-0 {
        @include bp(rev-small) {
            padding-#{$item}: 0 !important;
        }
    }
    
    .padding-bp-#{$item}-md-0 {
        @include bp(rev-medium) {
            padding-#{$item}: 0 !important;
        }
    }
    
    .padding-bp-#{$item}-lg-0 {
        @include bp(rev-large) {
            padding-#{$item}: 0 !important;
        }
    }
}