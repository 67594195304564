
/* Base settings */
*,
*::before,
*::after {
  box-sizing: border-box;
}

*{
    box-sizing: border-box;
    transition: color .15s, background .15s, border .15s, opacity .15s;
}

*:after, *:before  {
    box-sizing: border-box;
    outline: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

@-ms-viewport {
    width: device-width;
}

.row:after,
.row:before{
    display: none;
}

html{
    position: relative;
    min-height: 100%;
    box-sizing: border-box;
    font-size: 100%;
    // @include bp(excl-large){
    //     font-size: 80%;
    // }
    // @include bp(excl-xlarge){
    //     font-size: 80%;
    // }
    // @include bp(excl-xxlarge){
    //     font-size: 80%;
    // }
}


html,
body {
    position: relative;
    height: 100%;
}

body {
    margin: 0;
    height: 100%;
    min-width: 310px;
    font-family: $font-primary;
    font-weight: 400;
    line-height: 1;
    background-color: #ffffff;
}

a{
    cursor: pointer;
    text-decoration: none;
    outline: none;
    color: inherit;
    &:visited,
    &:focus,
    &:active,
    &:hover{
        text-decoration: none;
    }
} 

a:focus,
a:active,
a:visited{
    outline: none;
}

.a-hover{
    text-decoration: underline;
    &:hover {
        text-decoration: underline;
        color: $color-pink-normal;
    }
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

p{
    margin-top: 0;
    margin-bottom: 1.5rem;
    color: $color-black-normal;
    font-weight: 400;
    line-height: 1.4em;
    @include font-size(14px);
    line-height: 22px;
}

.p-small{
    p{
        line-height: 26px;
        @include font-size(14px);
    }
}

.p-normal{
    p{
        line-height: 26px;
        @include font-size(16px);
    }
}

.p-big{
    p{
        
    }
}

.p-large{
    p{
       
    }
}


ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

em, i, .italic {
    font-style: italic;
}

strong, .strong {
    font-weight: $font-bold;
}

small, .small {
    // @include font-size($font-size-small);
}


h1, .alfa,
h2, .beta,
h3, .gamma,
h4, .delta,
h5, .epsilon,
h6, .zeta {
    font-weight: 700;
    line-height: 1.2;
    margin: 0;
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-family: $font-secondary-bold;
    color: $color-black-normal;
}

h1, .alfa {
    @include font-size(32px);
    @include bp(xssmall) {
        @include font-size(36px);
    }
    @include bp(xsmall) {
        @include font-size(44px);
    }
    @include bp(small) {
        @include font-size(50px);
    }
    @include bp(medium) {
        @include font-size(56px);
    }
}

h2, .beta {
    @include font-size(32px);
    @include bp(xsmall) {
        @include font-size(36px);
    }
    @include bp(small) {
        @include font-size(40px);
    }
}

h3, .gamma {
    @include font-size(28px);
    @include bp(xsmall) {
        @include font-size(32px);
    }
    @include bp(small) {
        @include font-size(35px);
    }
}

h4, .delta {
    @include font-size(18px);
    @include bp(xsmall) {
        @include font-size(20px);
    }
    @include bp(small) {
        @include font-size(22px);
    }
    @include bp(medium) {
        @include font-size(24px);
    }
}

h5, .epsilon {
    @include font-size(32px);
    @include bp(xsmall) {
        @include font-size(36px);
    }
    @include bp(small) {
        @include font-size(40px);
    }
}

h6, .zeta {
    @include font-size(32px);
    @include bp(xsmall) {
        @include font-size(36px);
    }
    @include bp(small) {
        @include font-size(40px);
    }
}

.h-divider{
    position: relative;
    &:after{
        content: '';
        position: absolute;
        display: block;
        width: 22px;
        height: 2px;
        background-color: $color-blue-normal;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.h-divider-left{
    &:after{
        @include bp(medium){
            left: 0;
            transform: translateX(0);
        }
    }
}

.side-title{
    position: absolute;
    top: 50px;
    left: 40px;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
    @include font-size(80px);
    font-family: $font-secondary-bold;
    font-weight: 700;
    z-index: 7;
    opacity: 0.08;
    color: #cbcbd4;
    letter-spacing: 4px;
    @include bp(excl-xlarge){
        @include font-size(50px);
        left: 10px;
    }
    @include bp(excl-large){
        left: 10px;
        @include font-size(50px);
    }
    @include bp(excl-medium){
        left: 10px;
        @include font-size(50px);
    }
}

.side-title-left{
    top: 150px;
    -webkit-transform: rotateZ(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotateZ(-180deg);
}

.side-title-right{
    left: auto;
    top: 350px;
    right: 40px;
    @include bp(excl-xlarge){
        right: 10px;
    }
    @include bp(excl-large){
        right: 10px;
    }
    @include bp(excl-medium){
        right: 10px;
    }
}
