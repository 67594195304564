.section-product{
	position: relative;
	@include bp(small){
		padding-bottom: 360px;
	}
	@include bp(medium){
		padding-bottom: 410px;
	}
	@include bp(rev-small){
		padding: 40px 10px;
	}
	&:after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 950px;
		z-index: -1;
		background-color: $color-blue-dark;
		@include bp(small){
			top: 100px;
		}
	}
}

.products-block{
	@include bp(small){
		margin-top: -100px;
	}
}

.product-map{
	.product-content{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-bottom: 0;
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.14);
		@include bp(small){
			height: 200px;
		}
		&:hover{
			box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
		}
	}
	.product-text-block{
		width: 100%;
		max-width: 100%;
		flex: 0 0 100%;
		display: block;
		padding: 15px 15px 15px;
		@include bp(small){
			padding: 30px 20px 20px;
			width: 50%;
			max-width: 50%;
			flex: 0 0 50%;
		}
		@include bp(rev-small){
			p{
				max-height: 75px;
				@include font-size(12px);
			}
		}
	}
	.product-image-block{
		position: relative;
		height: 100px;
		width: 100%;
		max-width: 100%;
		flex: 0 0 100%;
		@include bp(small){
			height: 100%;
			width: 50%;
			max-width: 50%;
			flex: 0 0 50%;
		}
		&:after{
			display: none;
		}
	}
	.product-tag{
		padding: 6px 15px;
		@include font-size(14px);
		@include bp(rev-small){
			top: auto;
			bottom: 0;
		}
	}
}

.product-item{

}

.product-content{
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);
	background-color: #ffffff;
	@include bp(rev-medium){
		margin-bottom: 20px;
	}
}

.product-image-block{
	position: relative;
	overflow: hidden;
	@include bp(rev-medium){
		height: 400px;
	}
	&:after{
		content: '';
		position: absolute;
		bottom: 0;
		height: 125px;
		width: 100%;
		z-index: 0;
		background-color: rgba(0, 0, 0, 0.5);
		@include bp(rev-small){
			height: 120px;
		}
	}
}

.product-text-block{
	display: flex;
	align-items: center;
}

.product-padding{
	padding: 40px 40px 30px;
	@include bp(rev-small){
		padding: 40px 20px 30px;
	}
}

.product-padding-title{
	padding: 30px 20px 30px;
}

.product-tag{
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	padding: 8px 20px;
	font-family: $font-secondary-bold;
	font-weight: 700;
	background-color: $color-green-normal;
	color: #ffffff;
	@include font-size(23px);
	line-height: 1.2;
	letter-spacing: 0.5px;
	z-index: 6;
	&:empty {
		padding: 0;
	}
	@include bp(rev-medium){
		padding: 5px 10px;
		@include font-size(22px);
	}
}

.product-list{
}

.product-list-item{
	margin-bottom: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid #e1e5f6;
}

.product-list-title{
	display: block;
	margin-bottom: 5px;
	color: $color-pink-normal;
	font-family: $font-secondary-regular;
	font-weight: 400;
	line-height: 1.2;
	text-transform: uppercase;
	letter-spacing: 1.7px;
	@include font-size(17px);
}

.product-list-text{
	color: $color-black-normal;
	line-height: 1.4;
	@include font-size(14px);
}


.product-slider-main{
	height: 100%;
	.slick-list,
	.slick-track{
		height: 100%;
	}
}

.product-slider-main-item{
	height: 100%;
	.js-gallery{
		display: block;
		height: 100%;
	}
}

.product-slider-thumbnail{
	position: absolute;
	bottom: 10px;
	left: 0;
	width: 100%;
	// margin: 0 -5px;
	z-index: 1;
	.slick-current{
		.product-slider-thumbnail-item .bg-image{
			border: 2px solid $color-pink-normal;
		}
	}
	.slick-list{
		margin: 0 -5px;
	}
	.slick-slide{
		margin: 0 5px;
	}
}

.product-slider-thumbnail-item{
	// padding: 0 5px;
	cursor: pointer;
}

.product-slider-thumbnail-content{
	position: relative;
	height: 100px;
	@include bp(rev-small){
		height: 90px;
	}
}
