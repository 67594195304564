/* Web & icon fonts */

@font-face {
    font-family: $project-font-2;
    src: url('../fonts/webfonts/bebasneue/bebasneue-light-webfont.eot');
    src: url('../fonts/webfonts/bebasneue/bebasneue-light-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/webfonts/bebasneue/bebasneue-light-webfont.woff2') format('woff2'),
        url('../fonts/webfonts/bebasneue/bebasneue-light-webfont.woff') format('woff'),
        url('../fonts/webfonts/bebasneue/bebasneue-light-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $project-font-3;
    src: url('../fonts/webfonts/bebasneue/bebasneue-regular-webfont.eot');
    src: url('../fonts/webfonts/bebasneue/bebasneue-regular-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/webfonts/bebasneue/bebasneue-regular-webfont.woff2') format('woff2'),
        url('../fonts/webfonts/bebasneue/bebasneue-regular-webfont.woff') format('woff'),
        url('../fonts/webfonts/bebasneue/bebasneue-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $project-font-4;
    src: url('../fonts/webfonts/bebasneue/bebasneue-bold-webfont.eot');
    src: url('../fonts/webfonts/bebasneue/bebasneue-bold-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/webfonts/bebasneue/bebasneue-bold-webfont.woff2') format('woff2'),
        url('../fonts/webfonts/bebasneue/bebasneue-bold-webfont.woff') format('woff'),
        url('../fonts/webfonts/bebasneue/bebasneue-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $project-font-5;
    src: url('../fonts/webfonts/bebasneue/bebasneue-book-webfont.eot');
    src: url('../fonts/webfonts/bebasneue/bebasneue-book-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/webfonts/bebasneue/bebasneue-book-webfont.woff2') format('woff2'),
        url('../fonts/webfonts/bebasneue/bebasneue-book-webfont.woff') format('woff'),
        url('../fonts/webfonts/bebasneue/bebasneue-book-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
	font-family: $project-name;
	src:  url('../fonts/iconfonts/icomoon.eot?q0v86m');
	src:  url('../fonts/iconfonts/icomoon.eot?q0v86m#iefix') format('embedded-opentype'),
		url('../fonts/iconfonts/icomoon.ttf?q0v86m') format('truetype'),
		url('../fonts/iconfonts/icomoon.woff?q0v86m') format('woff'),
		url('../fonts/iconfonts/icomoon.svg?q0v86m#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: $project-name !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e909";
} 
.icon-tree:before {
  content: "\e906";
}
.icon-shop:before {
  content: "\e907";
}
.icon-food:before {
  content: "\e908";
}
.icon-email:before {
  content: "\e900";
}
.icon-uvozovky:before {
  content: "\e901";
}
.icon-telefon:before {
  content: "\e902";
}
.icon-sipka-L:before {
  content: "\e903";
}
.icon-map-pointer:before {
  content: "\e904";
}
.icon-sipka-R:before {
  content: "\e905";
} 