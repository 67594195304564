/* .checkbox.radiobox
    input#checkbox_payment_1.checkbox-hidden(type='radio', name="payment" required='')
    label.checkbox-label(for='checkbox_payment_1')
        span.checkbox-box
        span.checkbox-text Platba kartou */
       

/* Checkbox helpers */

.checkbox{
    clear: both;
    width: 100%;
    float: left;
    margin-bottom: 20px;
}

.checkbox-hidden {
    display: none;
    position: absolute;
    top: -9999px;
    left: -9999px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    opacity: 0;
}

.checkbox-label {
    margin: 0;
    padding-left: 0;
    min-height: inherit;
    width: 100%;
    display: block;
    color: black;
    vertical-align: middle;
    padding-top: 0;
    cursor: pointer;
    text-transform: none;
}

.checkbox-box {
    float: left;
    position: relative;
    width: 20px;
    height: 20px;
    top: -3px;
    border: 1px solid $color-black-normal;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    margin-left: 0;
}

.checkbox-text {
    display: block;
    position: relative;
    cursor: pointer;
    text-align: left;
    height: 100%;
    vertical-align: middle;
    color: rgba(0,0,0,0.4);
    font-size: 10px;
    overflow: hidden;
    text-transform: uppercase;
    margin-left: 0;
}


.checkbox-hidden:checked + .checkbox-label {
    .checkbox-box {
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 1px;
            left: 6px;
            width: 6px;
            height: 12px;
            border: solid #d2ac67;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(40deg);
            transform: rotate(40deg);
        }
    }
}

.radiobox{
    clear: both;
    width: 100%;
    float: left;
    margin-bottom: 20px;
    .checkbox-box {
        top: 0;
        width: 14px;
        height: 14px;
        border: 0;
        background-color: rgba(0,0,0,0.03);
        @include border-radius(100%);
    }
    .checkbox-hidden:checked + .checkbox-label {
        .checkbox-box {
            border: 2px solid $color-black-normal;
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                @include border-radius(100%);
                margin-top: -3px;
                margin-left: -3px;
                width: 6px;
                height: 6px;
                border: solid $color-black-normal;
            }
        }
    }
}
