/* Gutter helpers */

.gutter-50.row {
    margin-right: -25px;
    margin-left: -25px;
}

.gutter-50 > [class*="col-"],
.gutter-50 > [class*=" col-"] {
    padding-right: 25px;
    padding-left: 25px;
}


@include bp(large){
    .gutter-60-large.row {
        margin-right: -30px;
        margin-left: -30px;
    }

    .gutter-60-large > [class*="col-"],
    .gutter-60-large > [class*=" col-"] {
        padding-right: 30px;
        padding-left: 30px;
    }
}

@include bp(xxlarge){
    .gutter-60-xxlarge.row {
        margin-right: -30px;
        margin-left: -30px;
    }

    .gutter-60-xxlarge > [class*="col-"],
    .gutter-60-xxlarge > [class*=" col-"] {
        padding-right: 30px;
        padding-left: 30px;
    }
}

@include bp(xlarge){
    .gutter-40-large.row {
        margin-right: -20px;
        margin-left: -20px;
    }

    .gutter-40-large > [class*="col-"],
    .gutter-40-large > [class*=" col-"] {
        padding-right: 20px;
        padding-left: 20px;
    }
}

@include bp(xlarge){
    .gutter-50-large.row {
        margin-right: -25px;
        margin-left: -25px;
    }

    .gutter-50-large > [class*="col-"],
    .gutter-50-large > [class*=" col-"] {
        padding-right: 25px;
        padding-left: 25px;
    }
}

@include bp(xlarge){
    .gutter-100-large.row {
        margin-right: -50px;
        margin-left: -50px;
    }

    .gutter-100-large > [class*="col-"],
    .gutter-100-large > [class*=" col-"] {
        padding-right: 50px;
        padding-left: 50px;
    }
}

@include bp(xlarge){
    .gutter-120-large.row {
        margin-right: -60px;
        margin-left: -60px;
    }

    .gutter-120-large > [class*="col-"],
    .gutter-120-large > [class*=" col-"] {
        padding-right: 60px;
        padding-left: 60px;
    }
}

@include bp(medium){
    .gutter-100-medium.row {
        margin-right: -50px;
        margin-left: -50px;
    }

    .gutter-100-medium > [class*="col-"],
    .gutter-100-medium > [class*=" col-"] {
        padding-right: 50px;
        padding-left: 50px;
    }
}

.gutter-20.row {
    margin-right: -10px;
    margin-left: -10px;
}

.gutter-20 > [class*="col-"],
.gutter-20 > [class*=" col-"] {
    padding-right: 10px;
    padding-left: 10px;
}

@include bp(rev-xsmall){
    .gutter-20-small.row {
        margin-right: -10px;
        margin-left: -10px;
    }

    .gutter-20-small > [class*="col-"],
    .gutter-20-small > [class*=" col-"] {
        padding-right: 10px;
        padding-left: 10px;
    }
}


.gutter-10.row {
    margin-right: -5px;
    margin-left: -5px;
}

.gutter-10 > [class*="col-"],
.gutter-10 > [class*=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
}

@include bp(rev-xsmall){
    .gutter-10-small.row {
        margin-right: -5px;
        margin-left: -5px;
    }

    .gutter-10-small > [class*="col-"],
    .gutter-10-small > [class*=" col-"] {
        padding-right: 5px;
        padding-left: 5px;
    }
}


.gutter-8.row {
    margin-right: -4px;
    margin-left: -4px;
}

.gutter-8 > [class*="col-"],
.gutter-8 > [class*=" col-"] {
    padding-right: 4px;
    padding-left: 4px;
}


.no-gutter > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}

