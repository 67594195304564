/* Text helpers */

.txt-r {
    text-align: right;
}

.txt-c {
    text-align: center;
}

.txt-l {
    text-align: left;
}

.txt-upper {
    text-transform: uppercase;
}

.txt-lower {
    text-transform: lowercase;
}

.txt-italic{
    font-style: italic;
}

.txt-bold{
    font-weight: 700;
}

.txt-line {
    text-decoration: line-through;
}

.txt-underline {
    text-decoration: underline;
}


.h-thin{
    h1, .alfa,
    h2, .beta,
    h3, .gamma,
    h4, .delta, .delta-ext,
    h5, .epsilon,
    h6, .zeta{
        font-family: $font-secondary-light;
        font-weight: 300;
    }
}

.h-upper{
    h1, .alfa,
    h2, .beta,
    h3, .gamma,
    h4, .delta, .delta-ext,
    h5, .epsilon,
    h6, .zeta{
        text-transform: uppercase;
    }
}

.txt-lg-r {
    @include bp(rev-large) {
        text-align: right;
    }
}

.txt-lg-l {
    @include bp(rev-large) {
        text-align: left;
    }
}

.txt-lg-c {
    @include bp(rev-large) {
        text-align: center;
    }
}


.txt-md-l {
    @include bp(rev-medium) {
        text-align: left;
    }
}

.txt-md-r {
    @include bp(rev-medium) {
        text-align: right;
    }
}

.txt-md-c {
    @include bp(rev-medium) {
        text-align: center;
    }
}


.txt-sm-l {
    @include bp(rev-small) {
        text-align: left;
    }
}

.txt-sm-r {
    @include bp(rev-small) {
        text-align: right;
    }
}

.txt-sm-c {
    @include bp(rev-small) {
        text-align: center;
    }
}


.txt-xs-c {
    @include bp(rev-xsmall) {
        text-align: center;
    }
}

.txt-xs-l {
    @include bp(rev-xsmall) {
        text-align: left;
    }
}

.txt-xs-r {
    @include bp(rev-xsmall) {
        text-align: right;
    }
}


.txt-xss-c {
    @include bp(rev-xssmall) {
        text-align: center;
    }
}

.txt-xss-l {
    @include bp(rev-xssmall) {
        text-align: left;
    }
}

.txt-xss-r {
    @include bp(rev-xssmall) {
        text-align: right;
    }
}