// GENERAL
$project-name: 'project-name';
$project-font-1: 'Montserrat';
$project-font-2: 'bebasneue light';
$project-font-3: 'bebasneue regular';
$project-font-4: 'bebasneue bold';
$project-font-5: 'bebasneue book';



// COMPASS VARIABLES
$default-border-radius: 3px;
$default-transition-duration: .3s;
$default-transition-property: all;
$default-transition-function : ease;


// GRID VARIABLES
$grid-columns: 12;
$grid-gutter-width: 30px;
$screen-xs-phone: 370px;
$screen-phone: 576px;
$screen-tablet: 770px;
$screen-desktop: 992px;
$screen-lg-desktop: 1200px;
$screen-xl-desktop: 1440px;
$screen-xxl-desktop: 1600px;

$container-tablet:             (720px + $grid-gutter-width);
$container-sm:                 $container-tablet;
$container-desktop:            (940px + $grid-gutter-width);
$container-md:                 $container-desktop;
$container-large-desktop:      (1140px + $grid-gutter-width);
$container-lg:                 $container-large-desktop;


// FONT SETTINGS
$font-size-small: 12px;
$font-size-smaller: 13px;
$font-size-normal: 14px;
$font-size-bigger: 16px;
$font-size-big: 18px;

$font-primary: $project-font-1, sans-serif;
$font-secondary-light: $project-font-2, sans-serif;
$font-secondary-regular: $project-font-3, sans-serif;
$font-secondary-bold: $project-font-4, sans-serif;
$font-secondary-book: $project-font-5, sans-serif;

$font-light: 300;
$font-regular: 400;
$font-bold: 700;

$alfa: 48px;
$beta: 36px;
$gamma: 24px;
$delta: 18px;
$epsilon: 16px;
$zeta: 14px;


// COLORS SETTINGS
$color-pink-normal: #f73e66;
$color-blue-dark: #383d4f;
$color-blue-normal: #454b61;
$color-green-normal: #00d4a6;
$color-black-normal: #4f5158;


// MEDIA QUERIES WIDTH
//Primary
// $xs: 543px;
// $sm: 766px;
// $md: 992px;
// $lg: 1220px;

// //Mobile
// $xs-small: 360px;
// $xs-normal: 460px;

// //Desktop
// $lg-small: 1100px;
// $lg-large: 1440px;
// $lg-large-xl: 1600px;

