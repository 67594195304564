.section-contact{
	position: relative;
	&:after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		background-color: $color-blue-dark;
		@include bp(small){
			top: 300px;
			height: calc(100% - 300px);
		}
	}
}

.contact-form-block{
	padding-bottom: 90px;
	padding-top: 40px;
	@include bp(rev-small){
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 50px;
		padding-top: 30px;
	}
}

.address-block{
	margin-bottom: 30px;
	@include bp(rev-small){
		padding-top: 40px;
		margin-bottom: 0;
		padding-left: 10px;
		padding-right: 10px;
		.beta{
			color: #ffffff;
		}
	}
	@include bp(small){
		margin-top: -300px;
	}
}

.address-item{
	margin-bottom: 30px;
}

.address-item-big{
	.address-content{
		overflow: hidden;
		height: auto;
		padding: 40px 330px 40px 40px;
		box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);
		border-top: 2px solid $color-pink-normal;
		@include bp(rev-large){
			padding-right: 260px;
		}
		@include bp(rev-medium){
			padding: 40px 120px 40px 20px;
		}
		@include bp(rev-small){
			padding-right: 20px;
		}
	}
	.address-link{
		font-weight: 600;
		margin: 0;
		margin-bottom: 10px;
		@include font-size(17px);
		@include bp(rev-large){
			@include font-size(14px);
		}
	}
	.address-name{
		@include font-size(65px);
		@include bp(rev-large){
			@include font-size(50px);
		}
		@include bp(rev-medium){
			@include font-size(40px);
		}
	}
	.address-icon{
		padding-right: 20px;
		@include font-size(20px);
		&.icon-telefon{
			@include font-size(18px);
		}
	}
}

.address-content{
	position: relative;
	padding: 20px 20px;
	justify-content: space-between;
	margin: 0!important;
	background-color: #ffffff;
	@include bp(small){
		padding: 10px 20px;
		height: 110px;
	}
	@include bp(excl-large){
		padding: 10px 10px;
	}
	@include bp(excl-xsmall){
		padding: 20px 10px;
	}
}

.address-name{
	color: $color-black-normal;
	font-family: $font-secondary-book;
	@include font-size(36px);
	font-weight: 400;
	line-height: 1.2;
	text-transform: uppercase;
	letter-spacing: 0.47px;
	@include bp(rev-large){
		@include font-size(30px);
	}
}

.address-position{
	display: block;
	margin-top: 5px;
	color: $color-pink-normal;
	font-family: $font-secondary-regular;
	@include font-size(17px);
	font-weight: 400;
	letter-spacing: 1.7px;
}

.address-link-group{
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	@include bp(rev-xsmall){
		margin-top: 15px;
	}
}

.address-link{
	display: flex;
	margin: 5px 0;
	text-align: left;
	color: $color-black-normal;
	@include font-size(13px);
	font-weight: 300;
	line-height: 1.2;
	text-transform: uppercase;
	&:hover{
		.address-text{
			color: $color-pink-normal;
		}
	}
}

.address-icon{
	color: $color-pink-normal;
	padding-right: 10px;
	@include font-size(16px);
}

.address-text{
	word-break: break-all;
}

.address-logo{
	@include bp(xsmall){
		margin-left: 35px;
	}
}

.address-perex{
	@include bp(rev-xsmall){
		display: block;
		text-align: center;
		margin-bottom: 10px!important;
	    width: 100%;
	}
}

.address-person-image{
	position: absolute;
	top: 0;
	right: 0;
	width: 260px;
	height: 100%;
	@include bp(rev-medium){
		top: auto;
		width: 110px;
		height: 150px;
		bottom: 0;
	}
	@include bp(rev-small){
		display: none;
	}
}