/* Form helpers */

%input {
    @include Font1;
    @include font-size(14px);
    // @include appearance(none);
    @include border-radius(0);

    padding: 5px 0;
    height: 50px;
    width: 100%;
    font-weight: 400;
    background-color: transparent;
    border: 0;
    color: #ffffff;
    border-bottom: 2px solid #ffffff;
    -webkit-appearance: none;
    outline: none!important;
    &:placeholder-shown{
        border-bottom: 2px solid $color-blue-normal;
    }
    &:focus {
        border-bottom-color: #ffffff;
    }
}

.form-input {
    @extend %input;
}

.form-textarea {
    @extend %input;
    height: 255px;
    padding: 15px 20px;
    line-height: 1.5em;
    border: 2px solid #ffffff;
    resize: none;
    &:placeholder-shown{
        border: 2px solid $color-blue-normal;
    }
    &:focus {
        border-color: #ffffff;
    }
}

.form-select{
    width: 100%;
    padding: 0 20px;
    height: 40px;
    border: 0;
    text-align: center;
    background-color: #ffffff;
    color: rgba(0,0,0,0.4);
    color: #000000;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    outline: none;
    -webkit-appearance: value;
    -moz-appearance: value;
    -ms-appearance: value;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
}


.form-label {
    display: block;
    margin: 0 0 10px;
    color: #f9f9f9;
    font-family: $font-secondary-regular;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.7px;
    @include font-size(17px);
}

.form-line {
    margin-bottom: 50px;
    &:last-child{
        margin-bottom: 20px;
    }
}

.form-line-top{
    margin-top: 20px;
    @include bp(small){
        margin-top: 75px;
    }
}

.form-line-submit{
    margin-top: 10px;
}

.form-placeholder{
    &.is-active{
        .form-label {
            -webkit-transform: translateX(20px) translateY(8px);
            transform: translateX(20px) translateY(8px);
            width: auto;
            font-size: 10px;
        }
    }
    .form-label {
        position: absolute;
        -moz-transform: translateX(20px) translateY(15px);
        -ms-transform: translateX(20px) translateY(15px);
        -o-transform: translateX(20px) translateY(15px);
        -webkit-transform: translateX(20px) translateY(15px);
        transform: translateX(20px) translateY(15px);
        cursor: text;
        top: 0;
        left: 0;
        transition: all .3s ease;
        padding: 1px;
        margin: 0;
        z-index: 9;
        text-transform: none;
    }
}
