/* Color helpers */

.bg-black {
    background-color: #000000;
}

.bg-blue-dark{
	background-color: $color-blue-dark;
}

.bg-gray-light{
    background-color: #f7f7f7;
}

.txt-white{
    color: #ffffff;
}

.txt-black{
    color: #000000;
}

.h-white{
	h1, .alfa,
	h2, .beta,
	h3, .gamma,
	h4, .delta,
	h5, .epsilon,
	h6, .zeta{
		color: #ffffff;
	}
}

.h-pink{
	h1, .alfa,
	h2, .beta,
	h3, .gamma,
	h4, .delta,
	h5, .epsilon,
	h6, .zeta{
		color: $color-pink-normal;
	}
}

.h-gray{
	h1, .alfa,
	h2, .beta,
	h3, .gamma,
	h4, .delta,
	h5, .epsilon,
	h6, .zeta{
		color: #cbcbd4;
	}
}

.p-white{
	p{
		color: #ffffff;
	}
}

.ul-white{
	li{
		color: #ffffff!important;
	}
}