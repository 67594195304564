/* Icon helpers */

.icon-r {
    margin-right: 15px;
}

.icon-l {
    margin-left: 15px;
}

.icon-size-small{
	@include font-size(12px);
}

.icon-size-normal{
	@include font-size(14px);
}

.icon-size-big{
	@include font-size(16px);
}