.stats-border{
	@include bp(rev-medium){
		margin-bottom: 3em;
	}
	&:after{
		content: '';
		position: absolute;
		top: 50%;
		right: 0;
		display: block;
		width: 2px;
		height: 400px;
		margin-top: -200px;
		background-color: #e1e5f3;
		@include bp(rev-medium){
			top: auto;
			bottom: 0;
			width: -webkit-calc(100% - 15px);
			width: 250px;
			height: 2px;
			margin-top: 0;
			left: 50%;
			right: auto;
			transform: translateX(-50%);
		}
	}
}

.stats-group{
	text-align: center;
	@include bp(medium){
		margin-top: 6em;
	}
	@include bp(rev-medium){
		margin-top: 3em;
		margin-bottom: 1em;
	}
}

.stats-item{
	display: inline-block;
	padding: 0 10px;
	@include bp(xxlarge){
		padding: 0 20px;
	}
	@include bp(rev-large){
		padding: 0 5px;
	}
	@include bp(rev-medium){
		padding: 0 12px;
	}
	@include bp(rev-xssmall){
		padding: 0 5px;
	}
}

.stats-number{
	display: block;
	margin-bottom: 25px;
	color: $color-pink-normal;
	font-family: $font-secondary-light;
	font-weight: 300;
	line-height: 1;
	@include font-size(80px);
	letter-spacing: 0.43px;
	@include bp(rev-large){
		@include font-size(70px);
	}
	@include bp(rev-medium){
		margin-bottom: 15px;
		@include font-size(52px);
	}
	@include bp(rev-small){
		@include font-size(50px);
	}
	@include bp(rev-xsmall){
		@include font-size(40px);
	}
}

