/* Size helpers */

.w-full {
    width: 100%;
}

.w-half {
    width: 50%;
}

.w-quarter {
    width: 25%;
}


.h-full {
    height: 100%;
}

.h-half {
    height: 50%;
}

.h-quarter {
    height: 25%;
}